<script lang="ts">
import { defineComponent, ref } from 'vue'
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha'
import _ from 'lodash'
import apiClient from '@/api/api'
import Icon from './Icon.vue'

const emptyReturnForm = {
  phone: '',
  orderNumber: '',
  name: '',
  adress: '',
  rows: [
    {
      artno: '',
      qty: 1,
      reason: null,
      desc: ''
    }
  ]
}
const emptyContactForm = {
  phone: '',
  orderNumber: '',
  name: '',
  email: '',
  message: ''
}

export default defineComponent({
  name: 'FormsComponent',
  components: { VueHcaptcha, Icon },
  setup() {
    const captcha = ref<any>(null)
    return { captcha }
  },
  data() {
    return {
      loading: false,
      sent: false,
      failed: null,
      hcaptchaSiteKey: 'fbe6ffe7-d25b-40ca-937d-6e0cea862ce0',
      returnFormReasons: [
        '1. Fel produkt',
        '2. Fel storlek',
        '3. Fel på varan, uppge felet i reklamationsfältet',
        '4. Produkten motsvarar ej förväntningarna',
        '5. Annat, beskriv i reklamationsfältet'
      ],
      hcaptchaForm: {
        token: null as any
      },
      returnForm: _.cloneDeep(emptyReturnForm),
      contactForm: _.cloneDeep(emptyContactForm),
      type: ''
    }
  },
  methods: {
    resetForms() {
      this.returnForm = _.cloneDeep(emptyReturnForm)
      this.contactForm = _.cloneDeep(emptyContactForm)
      this.sent = false
      this.hcaptchaForm.token = null
      this.captcha.value?.reset()
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCaptchaVerify(token: string, ekey: any) {
      this.hcaptchaForm.token = token
    },
    sendForm(type: string, data: any) {
      if (this.loading) {
        return
      }

      this.loading = true
      this.failed = null

      data.captcha = this.hcaptchaForm.token

      apiClient
        .submitForm(type, data)
        .then(() => {
          this.sent = true
        })
        .catch((err) => {
          this.failed = err
        })
        .finally(() => {
          this.loading = false
          this.hcaptchaForm.token = null
          this.captcha.value?.reset()
        })
    }
  }
})
</script>

<template>
  <div>
    <div v-if="sent">
      Skickat!
      <button @click.prevent="resetForms()">Skicka igen</button>
    </div>
    <div v-else-if="type === 'return'" class="form">
      <form @submit.prevent="sendForm('return', returnForm)">
        <div>
          <label for="phone">Telefonnummer:</label>
          <input
            id="phone"
            v-model="returnForm.phone"
            type="text"
            name="phone"
            autocomplete="tel"
          />
        </div>
        <div>
          <label for="orderNumber">Ordernummer:</label>
          <input id="orderNumber" v-model="returnForm.orderNumber" type="text" name="orderNumber" />
        </div>
        <div>
          <label for="name">Namn:</label>
          <input id="name" v-model="returnForm.name" type="text" name="name" autocomplete="name" />
        </div>
        <div>
          <label for="adress">Adress:</label>
          <textarea
            id="adress"
            v-model="returnForm.adress"
            type="text"
            name="adress"
            autocomplete="street-address"
          />
        </div>
        <div class="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Artikelnummer</th>
                <th>Antal</th>
                <th>Orsak</th>
                <th>Vid reklamation, uppge felet</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(returnRow, i) in returnForm.rows" :key="i">
                <td>
                  <input v-model="returnRow.artno" style="width: 12rem" type="text" required />
                </td>
                <td>
                  <input
                    v-model="returnRow.qty"
                    style="width: 3rem"
                    type="number"
                    min="1"
                    step="1"
                  />
                </td>
                <td>
                  <div class="select-wrapper">
                    <select v-model="returnRow.reason">
                      <option
                        v-for="(reason, index) in returnFormReasons"
                        :key="index"
                        :value="reason"
                      >
                        {{ reason }}
                      </option>
                    </select>
                    <span class="icon-container">
                      <Icon name="chevronDown" type="far" />
                    </span>
                  </div>
                </td>
                <td><input v-model="returnRow.desc" type="text" /></td>
                <td>
                  <button
                    class="small"
                    :disabled="i === 0"
                    @click.prevent="returnForm.rows.splice(i, 1)"
                  >
                    Ta bort rad
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="5">
                  <button
                    class="small"
                    @click.prevent="
                      returnForm.rows.push({
                        artno: '',
                        qty: 1,
                        reason: null,
                        desc: ''
                      })
                    "
                  >
                    Lägg till rad
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <vue-hcaptcha
          ref="captcha"
          :sitekey="hcaptchaSiteKey"
          @verify="onCaptchaVerify"
        ></vue-hcaptcha>
        <button class="btn" type="submit" :disabled="loading">
          <span v-if="loading">Skickar...</span>
          <span v-else>Skicka</span>
        </button>
      </form>
    </div>
    <div v-else-if="type === 'contact'" class="form">
      <form @submit.prevent="sendForm('contact', contactForm)">
        <div>
          <label for="phone">Telefonnummer:</label>
          <input
            id="phone"
            v-model="contactForm.phone"
            type="text"
            name="phone"
            autocomplete="tel"
          />
        </div>
        <div>
          <label for="orderNumber">Ev. Ordernummer:</label>
          <input
            id="orderNumber"
            v-model="contactForm.orderNumber"
            type="text"
            name="orderNumber"
          />

          <label for="name">Namn:</label>
          <input id="name" v-model="contactForm.name" type="text" name="name" autocomplete="name" />
        </div>
        <div>
          <label for="email">E-post:</label>
          <input
            id="email"
            v-model="contactForm.email"
            type="text"
            name="email"
            autocomplete="email"
          />
        </div>
        <div>
          <label for="message">Meddelande:</label>
          <textarea
            id="message"
            v-model="contactForm.message"
            type="text"
            name="message"
            rows="3"
          />
        </div>
        <vue-hcaptcha
          ref="captcha"
          :sitekey="hcaptchaSiteKey"
          @verify="onCaptchaVerify"
        ></vue-hcaptcha>
        <button type="submit" :disabled="loading">
          <span v-if="loading">Skickar...</span>
          <span v-else>Skicka</span>
        </button>
      </form>
    </div>
    <div v-if="failed" class="error">
      {{ failed }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.error {
  margin-top: 1rem;
  font-family: $inter;
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: -0.17px;
  color: #e42437;
}
label {
  display: block;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}
label,
th {
  font-family: $inter;
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: -0.17px;
  text-align: left;
}
input,
textarea,
select {
  padding: 0.4375rem 0.5rem;
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  font-family: $inter;
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: -0.17px;
  max-width: 100%;
  line-height: 17px;
}
.select-wrapper {
  position: relative;
  select {
    padding-right: 1rem;
  }
  .icon-container {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0.4375rem;
  }
  svg {
    width: 0.75rem;
    height: 0.75rem;
  }
}
textarea {
  min-height: 110px;
  width: 350px;
}
.table-wrapper {
  max-width: 100%;
  overflow: scroll;
}
table {
  margin: 1rem 0 2rem 0;
}
button {
  color: #ffffff;
  background: #004999;
  border-radius: 2px;
  border: none;
  padding: 0.75rem 1.5rem;
  transition: 0.2s ease;
  font-family: $inter;
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: -0.2px;
  white-space: nowrap;
  &:disabled {
    opacity: 0.5;
  }
  &.small {
    font-size: 0.8125rem;
    line-height: 17px; // To match the inputs
    padding: 0.5rem 1rem;
  }
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
#hcap-script {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
