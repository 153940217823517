<script lang="ts">
import { defineComponent } from 'vue'
import apiClient from '@/api/api'
import PageNotFound from '@/components/PageNotFound.vue'
import Forms from '@/components/Forms.vue'
import MenuItem from '@/components/MenuItem.vue'
import { storeToRefs } from 'pinia'
import { useStore } from '../store'
import { isClient } from '@/utils/helpers'

export default defineComponent({
  name: 'PageView',
  components: { PageNotFound, Forms, MenuItem },
  beforeRouteEnter(to, from, next) {
    if (isClient && useStore().pages[to.params.id as string]) {
      next()
      return
    }
    apiClient.fetchPage(to.params.id as string).then(resp => {
      next((vm: any) => {
        vm.setPage(resp)
      })
    })
  },
  beforeRouteUpdate(to, from, next) {
    apiClient.fetchPage(to.params.id as string).then(resp => {
      this.setPage(resp)
      next()
    })
  },
  setup() {
    const store = useStore()
    const { setPage, fetchPage } = useStore()
    const { menus, pages } = storeToRefs(store)
    return { setPage, fetchPage, menus, pages }
  },
  head() {
    if (!this.page) {
      return {}
    }
    return {
      title: this.page.title.rendered,
      meta: [
        {
          property: 'og:title',
          content: this.page.title.rendered,
          vmid: 'og:title'
        },
        {
          property: 'og:url',
          content: import.meta.env.VITE_FRONTEND_URL + this.$route.path,
          vmid: 'og:url'
        },
        {
          property: 'og:description',
          content: this.page.seoframework
            ? this.page.seoframework.description
            : this.page.excerpt.rendered.replace(/<[^>]+>/g, ''),
          vmid: 'og:description'
        },
        {
          name: 'description',
          content: this.page.seoframework
            ? this.page.seoframework.description
            : this.page.excerpt.rendered.replace(/<[^>]+>/g, '')
        }
      ]
    }
  },
  computed: {
    navService() {
      return this.menus.services ? this.menus.services.items : []
    },
    contentAndFormExtract() {
      if (!this.page) {
        return ['', null]
      }
      if (this.page.content.rendered.indexOf('#include-return-form#') !== -1) {
        return [
          this.page.content.rendered.replace('#include-return-form#', ''),
          'return'
        ]
      }
      if (this.page.content.rendered.indexOf('#include-contact-form#') !== -1) {
        return [
          this.page.content.rendered.replace('#include-contact-form#', ''),
          'contact'
        ]
      }
      return [this.page.content.rendered, null]
    },
    page() {
      return this.pages[this.$route.params.id as string]
    }
  },
  // Server-side only
  serverPrefetch() {
    return this.fetchPage(this.$route.params.id as string)
  },
  // Client-side only
  watch: {
    '$route.params.id': {
      handler() {
        if (!this.page && this.page !== false) {
          this.fetchPage(this.$route.params.id as string)
        }
      },
      immediate: true
    }
  }
})
</script>

<template>
  <div v-if="page === false">
    <PageNotFound />
  </div>
  <div v-else-if="page">
    <div class="container">
      <div class="page-grid">
        <nav class="side-nav">
          <div v-for="(itemService, index) in navService" :key="index">
            <MenuItem :nav-item="itemService" />
          </div>
        </nav>
        <article>
          <h1 v-html="page.title.rendered" />
          <div class="content" v-html="contentAndFormExtract[0]" />

          <Forms
            v-if="contentAndFormExtract[1] !== null"
            :type="contentAndFormExtract[1]"
          />
        </article>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.container {
  margin: 1.5rem auto;
  max-width: 1600px;
  padding: 0 2rem;

  h1 {
    font-family: $brandon;
    font-weight: 700;
    font-size: 2.5rem;
    letter-spacing: -0.54px;
    text-transform: uppercase;
    margin: 2em 0 0.5em;
  }
}
.page-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  @media screen and (min-width: 1025px) {
    grid-template-columns: 1fr 3fr;
  }
}
img {
  max-width: 100%;
}
article :deep(.content) {
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $brandon;
    font-weight: 700;
    letter-spacing: -0.54px;
    text-transform: uppercase;
    margin: 2em 0 0.5em;
  }
  p,
  li {
    line-height: 2;
    font-weight: 300;
    font-size: 1rem;
    &.has-small-font-size {
      font-size: 75%;
    }
    strong {
      font-weight: 700;
    }
    a {
      color: $main;
      position: relative;
      font-weight: 400;
      //hover underline effect
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -5px;
        left: 0;
        background-color: $main;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
      }
      &:hover::before {
        visibility: visible;
        transform: scaleX(1);
      }
    }
  }
}
.side-nav {
  display: none;
  @media screen and (min-width: 1025px) {
    display: block;
    margin-top: 153px;
  }
  div {
    + div {
      margin-top: 0.875rem;
    }
    a {
      color: $black;
      position: relative;
      //hover underline effect
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -5px;
        left: 0;
        background-color: $main;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
      }
      &:hover::before {
        visibility: visible;
        transform: scaleX(1);
      }
    }
  }
}
</style>
